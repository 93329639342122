<!-- views/Callback.vue -->

<template>
  <div class="loading-container">
    <van-loading size="45px">loading</van-loading>
  </div>
</template>

<script>
import { getThirdPartUserInfo } from "@/api/eformApi";
import { zipDecode } from "@/utils/zipcoder";
import {
    Loading,
    Toast
} from 'vant';

export default {
    name: "Callback",
    components: {
        [Loading.name]: Loading
    },
    data() {
        return {
            callbackSource: ''
        };
    },
    methods: {

    },

    created() {
        //fix 由于哈希路由导致的回调URL参数异常问题
        //https://localhost:8080/?code=xxx&state=xxx#/line/callback?tpid=xxx        
        const cburl = new URL(window.location.href)
        const cbcode = cburl.searchParams.get('code')
        if (cbcode !== null) {
            // 如果能获得code，说明需要处理url并跳转，如果不能获得code，不用处理
            const replaceUrl = cburl.origin + '/' + cburl.hash + '&' + cburl.search.substring(1)
            return location.replace(replaceUrl)
        }

        this.callbackSource = this.$route.params.thirdPart
        //第三方Oauth授权回调的参数
        const authCode = this.$route.query.code
        const state = this.$route.query.state
        //透传的参数
        const tpid = this.$route.query.tpid
             
        if (this.callbackSource && authCode && state && tpid) {
            getThirdPartUserInfo({
                thirdPart: this.callbackSource,
                authCode: authCode,
                state: state,
                tpid: tpid,
                hostPath: window.location.href.split('#')[0]
            }).then(res => {
                if (!res.success && !res.data) {
                    console.info(res.errMsg)
                    Toast.fail({
                        type: "fail",
                        message: res.errMsg,
                    });
                    this.$router.push({ name: 'NotFound' })
                    return
                }
                let nextRouter = JSON.parse(zipDecode(res.data.nextRouter))
                console.info(nextRouter)
                if (res.success) {
                    nextRouter.params.tpid = tpid
                    if (nextRouter.name === 'EnterNumber' && res.data.email) {
                        nextRouter.params.email = res.data.email
                    }
                    this.$router.push(nextRouter)
                } else {
                    console.info(res.errMsg)
                    Toast.fail({
                        type: "fail",
                        message: res.errMsg,
                    });
                    this.$router.push(nextRouter)
                }
            }).catch((error) => {
                console.info("Third-party info get failed: "+error)
                Toast.fail({
                    type: "fail",
                    message: 'Third-party info get failed',
                });
                this.$router.push({name: 'NotFound'})
            })
        } else {
            console.info(this.$route.params.thirdPart + " callback params is not valid: " + JSON.stringify(this.$route.query))
            Toast.fail({
                type: "fail",
                message: 'callback params is not valid',
            });
            this.$router.push({name: 'NotFound'})
        }
    },

    mounted() {

    },
}
</script>

<style scoped>
   .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60vh;
    }
</style>